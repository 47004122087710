<template>
  <div id="DiligentJob">
    <van-notice-bar color="rgba(51, 51, 51, 1)" background="rgba(232, 247, 241, 1)">
      <van-icon name="info" color="rgba(16, 120, 117, 1)" /> 对申请单位要求
      <template #right-icon>
        <span v-if="tipsShow" style="color:rgba(35, 171, 114, 1)" @click="tipsShow = false">收起</span>
        <span v-else style="color:rgba(35, 171, 114, 1)" @click="tipsShow = true">查看详情</span>
      </template>
    </van-notice-bar>
    <div v-if="tipsShow" class="tips-div">
      <p>1.申请单位可根据学生的劳动表现向学生处提出奖励意见，对不守纪律，敷衍了事的学生，在向学生资助管理中心反映情况后，可以辞退； </p>
      <p>2.学生每人每月参加勤工助学的时间一般不超过40小时；</p>
      <p>3.校内单位需要设立勤工助学岗位时，应按程序向学生处提出申请，擅自设岗使用学生的相关费用由单位自行解决；</p>
      <p>4.申请单位要指定专人对勤工助学的学生进行考勤，每月15日前（若遇假期顺延）向学生处学生资助中心报上月学生工作情况考勤表。</p>
    </div>
    <!-- 查询 -->
    <div class="search-input-div">
      <van-search class="search-input" v-model="query.stuName" show-action placeholder="请输入关键词查询" @search="toQuery">
        <template #action>
          <div @click="toQuery">搜索</div>
        </template>
      </van-search>
    </div>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad" style="margin-bottom:64px">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index) in data" :key="index">
        <van-cell>
          <template #title>
            <div class="custom-title margin6 hidden-text95" style="color:#23AB72">
              {{row.schoolYear}}{{ codeConvertLabel(row.schoolTerm,dict.xj_xqgl) }}
            </div>
            <div class="custom-title hidden-text95">
              {{row.jobName}} <van-icon name="arrow" color="#23AB72" @click="toDetail(row)" />
            </div>
          </template>
          <template #label>
            <div class="custom-centent margin6 hidden-text95">
              <van-icon name="orders-o" /> 单位/科室: {{row.unitName}}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <van-icon name="manager-o" /> 联系人: {{row.contactName}}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <van-icon name="newspaper-o" /> 岗位类型: {{codeConvertLabel(row.jobType,dict.daily_job_type) }}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <van-icon name="notes-o" /> 需求人数: {{ row.jobDemand +"人/岗" }}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <van-icon name="refund-o" /> 报酬标准(元): {{ row.jobDemand }}
            </div>
          </template>
          <template #right-icon>
            <span v-if="statustag(row.approvalStatus,'success')" class="audit success-color b-color-success">审核通过</span>
            <span v-else-if="statustag(row.approvalStatus,'info')" class="audit info-color b-color-info">待审核</span>
            <span v-else-if="statustag(row.approvalStatus,'error')" class="audit error-color b-color-error">审核不通过</span>
            <span v-else-if="statustag(row.approvalStatus,'warning')" class="audit warning-color b-color-warning">审核驳回</span>
            <div class="ckeckbox-st" @click="checkHandel(row)" v-if="showAuditBtn">
              <van-checkbox v-model="row.checked" icon-size="21px" shape="square"></van-checkbox>
            </div>
          </template>
        </van-cell>
        <van-cell id="opt-style">
          <template #title>
            <span v-if="statustag(row.approvalStatus,'success')" class="success-color hidden-text95">
              <div class="status-tag ok-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'info')" class="info-color hidden-text95">
              <div class="status-tag info-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'error')" class="error-color hidden-text95 ">
              <div class="status-tag error-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'warning')" class="warning-color hidden-text95 ">
              <div class="status-tag warning-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
          </template>
          <template #right-icon>
            <van-button class="table-del-btn" @click="toDel(row)" :disabled="[301,9].indexOf(row.approvalStatus) > -1" :loading="row.delLoading" plain round size="small" type="warning">删除</van-button>
            <van-button class="table-edit-btn" @click="toEdit(row)" :disabled="[301,9].indexOf(row.approvalStatus) > -1" plain round size="small" type="primary">编辑</van-button>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <div class="submit-button shadow-style">
      <van-button round block type="primary" @click="doAdd">新 增</van-button>
    </div>
    <div class="batch-button" v-if="showAuditBtn">
      <van-row>
        <van-col span="5">
          <van-checkbox class="batch-checked" @click="selcetAll" v-model="checkedAll" checked-color="#23AB72">全选</van-checkbox>
        </van-col>
        <van-col span="9">
          <div class="batch-checked-text" v-html="selectionText"></div>
        </van-col>
        <van-col span="10">
          <van-button round block type="primary" color="#23AB72" :disabled="this.selections.length ===0" @click="batchAudit()">批量审批</van-button>
        </van-col>
      </van-row>
    </div>
    <van-dialog v-model="showAudit" title="审批" :showConfirmButton="false" width="90%">
      <div @click="toCloseAudit(false)" class="cancel">取消</div>
      <van-form ref="form">
        <van-field v-model="audit.appendData.field1" :border="true" label="计费方式" placeholder="选择奖计费方式" :rules="[{ required: true, message: '选择认定等级' }]">
          <template #input>
            <select class="select" @change="clickSelect" v-model="filed">
              <option value="0">请选择计费方式</option>
              <option v-for="(item,index) in jobType" :key="index" :value="item.value">{{item.label}}</option>
            </select>
          </template>
        </van-field>
        <van-field v-model="audit.appendData.field2" :border="true" type="number" label="酬金标准" placeholder="请输入酬金标准" :rules="[{ required: true, message: '请输入酬金标准' }]" />
        <van-field v-model="audit.appendData.opinion" :border="true" rows="3" label="审核意见" type="textarea" maxlength="50" placeholder="请输入审核意见" show-word-limit :rules="[{ required: true, message: '请输入审核意见' }]" />
      </van-form>
      <van-row style="padding:10px 10px 20px 10px" justify="center">
        <van-col span="8" align="center">
          <van-button type="warning" :loading="auditLoading1" :disabled="auditDisabled1" round style="width:80%" @click="confirmAudit(8)">驳回</van-button>
        </van-col>
        <van-col span="8" align="center">
          <van-button type="danger" :loading="auditLoading2" :disabled="auditDisabled2" round style="width:80%" @click="confirmAudit(9)">拒绝</van-button>
        </van-col>
        <van-col span="8" align="center">
          <van-button type="primary" :loading="auditLoading3" :disabled="auditDisabled3" round style="width:80%" @click="confirmAudit(1)">同意</van-button>
        </van-col>
      </van-row>
    </van-dialog>

    <switch-role @change="toQuery()"></switch-role>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import crudStuDisciplinaryManage from '@/api/modules/daily/disciplinary'
import batchAudit from "@/mixins/batchAudit";
import fileUpload from "@/components/FileUpload";

export default {
  name: "StuPayReport",
  mixins: [initData, batchAudit],
  components: { fileUpload },
  dicts: ["xj_shzt", "xj_nj", "xj_xqgl", "daily_job_type"],
  data() {
    return {
      loading: false,
      showAuditBtn: false,
      showAdd: false,
      audit: {
        appendData: {
          opinion: "同意",
          like: [],
          field1: "",
          field2: "",
        },
        coreKey: "",
        type: "RCGL",
        callback: "api/jobSetting/approve",
        mkCode: "SG",
        coreCode: "SGGL",
        status: 9,
      },
      showUpload: false,
      tipsShow: false,
      jobType: [
        {
          label: '小时',
          value: '小时',
        },
        {
          label: '天数',
          value: '天',
        },
        {
          label: '月份',
          value: '月',
        },
      ],
      filed: ""
    }
  },
  methods: {
    clickSelect() {
      if (this.filed === '0') {
        this.audit.appendData.field1 = null
      } else {
        this.audit.appendData.field1 = this.filed
      }
    },
    resetField() {
      this.audit = {
        appendData: {
          opinion: "同意",
          like: [],
          field3: "",
        },
        coreKey: "",
        type: "RCGL",
        callback: "api/jobSetting/approve",
        mkCode: "SG",
        coreCode: "SGGL",
        status: 9,
      }
    },
    beforeInit() {
      this.url = "/api/jobSetting";
      this.sort = ['approvalStatus,asc', 'createTime,desc', 'schoolYear,desc'];
      this.beforeRefresh()
      return true;
    },
    beforeRefresh() {
      let roles = this.user.roles.map((item) => {
        return item.dataScope
      })
      if (roles.indexOf('自身') > -1) {
        this.query.approvalStatus = '-1'
        this.showAdd = false
        this.showAuditBtn = false
      }
      if (roles.indexOf('本级') > -1) {
        this.query.approvalStatus = '2,201,208,3,9,301,308'
        this.showAdd = true
        this.showAuditBtn = false
      }
      if (roles.indexOf('全部') > -1) {
        this.showAdd = false
        this.showAuditBtn = true
        let roleName = this.user.roles.map((item) => {
          return item.name
        })
        if (roleName.indexOf('超级管理员') === -1) {
          this.query.approvalStatus = '3,9,301,308'
        }
      }
    },
    toUpload(data) {
      this.uploadForm.id = data.id
      if (data.files) {
        this.uploadForm.fileId = JSON.parse(data.files)
        this.refsh = false
        this.$nextTick((e) => {
          this.refsh = true
        })
      } else {
        this.uploadForm.fileId = []
      }
      this.showUpload = true
    },
    uploadResponse(fileIds) {
      this.uploadForm.fileId = fileIds
    },
    closeUploadFile() {
      this.uploadForm = {
        fileId: [],
        id: null,
      }
      this.refsh = false
      this.$nextTick((e) => {
        this.refsh = true
      })
      this.showUpload = false
    },
    uploadPunishResolve(action, done) {
      if (this.uploadForm.fileId && this.uploadForm.fileId.length > 0 && action === 'confirm') {
        crudStuDisciplinaryManage.uploadFile(this.uploadForm).then((res) => {
          this.$notify({
            message: '上传成功',
            type: 'success',
            duration: 2000,
          })
          done()
          this.toQuery()
        })
      } else {
        done(false)
        this.$notify({
          message: '请上传决定书',
          type: 'warning',
          duration: 2000,
        })
      }
    },
    toDetail(data) {
      this.$router.push({
        path: "/js/job/setting/detail",
        query: { id: data.id, status: data.cancelApprovalStatus },
      });
    },
    doAdd() {
      this.$router.push({
        path: "/js/job/setting/form",
      });
    },
    toEdit(data) {
      this.$router.push({
        path: "/js/job/setting/form",
        query: { id: data.id },
      });
    },
    // 删除操作
    toDel(data) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "你确定要删除该条数据吗？",
        })
        .then(() => {
          this.$set(data, "delLoading", true);
          crudStuDisciplinaryManage.del([data.id])
            .then((res) => {
              this.$notify({
                type: "success",
                message: "操作成功",
                duration: 2000,
              });
              this.init();
              this.$set(data, "delLoading", false);
            })
            .catch((e) => {
              this.$set(data, "delLoading", false);
            });
        })
        .catch(() => {
          this.$set(data, "delLoading", false);
        });
    },
    async confirmAudit(type) {
      let roles = this.user.roles.map((item) => {
        return item.dataScope
      })
      let value = []
      if (roles.indexOf('全部') > -1) {
        value = this.selections.filter((item) => {
          return ['3'].indexOf(item.approvalStatus + '') > -1
        })
      }
      console.log(this.audit)
      this.audit.status = type
      if (value.length > 0) {
        await this.$refs.form.validate().then(
          (res) => {
            this.loadingStatus(type)
            this.selections.forEach((element) => {
              this.audit.coreKey = element.id;
              this.auditApi(this.audit).then((resp) => {
                if (resp.success) {
                  this.toCloseAudit(true);
                  this.resetField();
                }
              });
            });
            this.toQuery()
          },
          (err) => { }
        );
      } else {
        this.$notify({
          type: "success",
          message: "请选择需要自己审核的数据",
          duration: 2000,
        });
      }
    },

  }
}
</script>

<style lang="scss" scoped>
#DiligentJob {
  .tips-div {
    background-color: rgb(232, 247, 241);
    color: rgb(51, 51, 51);
    padding: 8px 15px 4px 15px;
    font-size: 15px;
    line-height: 22px;
    span {
      font-weight: 500;
    }
    p {
      margin: 5px 0;
    }
  }

  .detail-pos {
    position: absolute;
    top: 50%;
    right: 15px;
  }
  .ckeckbox-st {
    font-size: 21px;
    top: 50%;
    position: absolute;
    right: 5%;
  }
  .cancel {
    color: #999999;
    position: absolute;
    top: 20px;
    right: 30px;
  }
}
.ckeckbox-st {
  font-size: 21px;
  top: 50%;
  position: absolute;
  right: 5%;
}

.cancel {
  color: #999999;
  position: absolute;
  top: 20px;
  right: 30px;
}

.select {
  border: 0px #fff solid;
  width: 100%;
  height: 26px;
  background-color: #fff;
  font-size: 15px;
  color: #323233;
  margin-left: -8px;
}
</style>